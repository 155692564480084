<template>
  <div>

    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/postcode">
          <b-button
            variant="primary"
            size="md"
          >
            <span class="align-middle">Back</span>
          </b-button>
        </router-link>
      </div>
    </div>

    <div>
      <b-row>
        <b-col>
          <b-card title="Deleted Postcode">
            <b-row class="my-1">

              <b-col
                cols="6"
              >
                <b-form-group
                  class="mb-0 align-items-center"
                  label-cols-sm="2"
                  label-align-sm="left"
                  label-size="sm"
                  label-for="perPageSelect"
                  label="Per page"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                class="ml-auto"
              >
                <b-form-group
                  label="Filter"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0 align-items-center"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :busy.sync="isBusy"
              :items="items"
              :filter="filter"
            >
              <template #table-busy>
                <div class="text-center text-success my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(ID)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(postcode)="data">
                {{ data.value }}
              </template>
              <template #cell(actions)="data">
                <div class="d-flex">
                  <b-button
                    variant="flat-success"
                    class="btn-icon"
                    @click="restore(data.item)"
                  >
                    Restore
                  </b-button>
                  <b-button
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deletePostcode(data.item)"
                  >
                    Delete Permanently
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              size="sm"
              class="my-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" />
              </template>
            </b-pagination>
          </b-card>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const postcodeModule = createNamespacedHelpers('postcode')
export default {
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 5,
      pageOptions: [5, 10, 20, 50, 100],
      filter: null,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'code', label: 'Postcode' },
        { key: 'actions', label: 'Action' },
      ],
      items: [],
    }
  },
  mounted() {
    this.fetchAllDeletedPostcodes()
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...postcodeModule.mapActions(['FETCH_ALL_DELETED_POSTCODES', 'DELETE_POSTCODE_PERMANENT', 'RESTORE_POSTCODE']),

    async fetchAllDeletedPostcodes() {
      try {
        this.isBusy = true
        this.items = await this.FETCH_ALL_DELETED_POSTCODES()
        this.totalRows = this.items.length
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },
    async deletePostcode(item) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete postcode "${item.code}". This action cannot be reverted`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.UPDATE_LOADER(true)
              const resp = await this.DELETE_POSTCODE_PERMANENT(item.id)
              if (resp) {
                this.fetchAllDeletedPostcodes()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Postcode deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Postcode has been deleted successfully!',
                  },
                })
              }
              this.UPDATE_LOADER(false)
            } catch (error) {
              this.UPDATE_LOADER(false)
            }
          }
        })
    },
    async restore(item) {
      try {
        this.UPDATE_LOADER(true)
        const resp = await this.RESTORE_POSTCODE(item.id)
        if (resp) {
          this.fetchAllDeletedPostcodes()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Postcode restored!',
              icon: 'PlusIcon',
              variant: 'success',
              text: 'Postcode has been restored successfully!',
            },
          })
        }
        this.UPDATE_LOADER(false)
      } catch (error) {
        this.UPDATE_LOADER(false)
      }
    },
  },

}
</script>

<style lang="scss" scoped>
</style>
